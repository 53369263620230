import { graphql, Link } from "gatsby"
import moment from "moment"
import React from "react"
import { Message } from "semantic-ui-react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BLUE, GREEN, RED } from "../components/ui/colors"

const HomeSection = styled.section`
  h2 {
    font-size: 2.5rem;
    padding: 1rem 0 0;
  }
  h3 {
    font-size: 1.8rem;
    margin-top: 2.3rem;
    font-weight: 500;

    &:first-of-type {
      color: ${RED};
    }
    &:nth-of-type(2) {
      color: ${BLUE};
    }
    &:nth-of-type(3) {
      color: ${GREEN};
    }
  }
`

const IndexPage = ({ data }) => {
  // Filter out only future walks
  const futureWalks = data.latestWalks.edges.filter(
    ({ node }) => moment(node.startDate, "YYYY-MM-DD") > moment()
  )

  const nextWalk = futureWalks.pop()
  console.log("nextWalk:", nextWalk)

  /*
    Commented out code for testing with no future walks
  */
  // const nextWalk = data.latestWalks.edges.reverse().pop()
  // console.log(nextWalk)

  return (
    <Layout
      headerImage={data.headerImage.childImageSharp}
      headerText="North Mississippi Emmaus Community"
      headerSize="lg"
      headerImageFilter={0.1}
    >
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      {nextWalk && (
        <Message info>
          <Message.Header>
            Upcoming: {nextWalk.node.gender === "f" ? `Women's` : `Men's`} Walk
            #{nextWalk.node.walkNumber} on{" "}
            {moment(nextWalk.node.startDate, "YYYY-MM-DD").format("LL")}!
          </Message.Header>
          <p>
            Click{" "}
            <Link to={`/pilgrim-lists/${nextWalk.node.walkNumber}`}>here</Link>{" "}
            to view the pilgrim list.
          </p>
        </Message>
      )}
      <HomeSection>
        <h2>Our Mission</h2>
        <hr />
        <h3>Developing Christian leaders.</h3>
        <p>
          We invite Christians who are active members of local churches to come
          to the three day weekend. While on their walk, they are challenged to
          take on the disciplines of piety, study, and Christian action for the
          rest of their lives.
        </p>

        <h3>Strengthening local congregations.</h3>
        <p>
          We want pilgrims to return to their local churches and continue to
          offer themselves in service to God through their congregations. They
          take back their understanding of a life marked by piety, study, and
          Christian action. They bring home a renewed energy for loving God and
          neighbor. They bring back a revitalized desire to offer sacrificial
          love within the church.
        </p>
        <h3>Christianizing the environment.</h3>
        <p>
          We want the pilgrims to return to their homes, places of work, school,
          neighborhoods, and be faithful witnesses to the grace of Jesus Christ.
          It is our hope and vision that their witness will make a difference in
          their world, that their lives will be marked by honesty and integrity,
          and that the light of Christ will shine through their words and deeds.
        </p>
      </HomeSection>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    latestWalks: allSanityWalk(
      sort: { fields: startDate, order: DESC }
      limit: 20
    ) {
      edges {
        node {
          walkNumber
          startDate
          endDate
          gender
        }
      }
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }

    headerImage: file(relativePath: { eq: "forest.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
